import Vue from "vue";
import VueRouter from "vue-router";
import { isLoggedIn } from "../vue-apollo";
import i18n from "@/i18n";

Vue.use(VueRouter);

const UNGUARDED_PAGES = ["Login", "ForgotPassword"];

const routes = [
  {
    path: "/:lang([a-z][a-z])",
    component: {
      render: (c) => c("router-view"),
    },
    children: [
      {
        path: "forgotpassword/:username?/:token?",
        name: "ForgotPassword",
        component: () => import("../views/ForgotPassword.vue"),
        props: true,
      },
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "administration/:section?",
        name: "Administration",
        component: () => import("../views/Administration.vue"),
        props: true,
      },
      {
        path: "dashboard/:section?",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        props: true,
      },
      {
        path: "meals",
        name: "TodayMeals",
        component: () => import("../views/Meals.vue"),
      },
      {
        path: "meals/:residence_id/date/:date/type/:type",
        name: "ResidenceDateTypeMeals",
        component: () => import("../views/Meals.vue"),
      },
      {
        path: "meals/:residence_id",
        name: "ResidenceMeals",
        component: () => import("../views/Meals.vue"),
      },
      {
        path: "profile/:id?",
        name: "UserProfile",
        component: () => import("../views/Profile.vue"),
        children: [
          {
            path: "student_year/:student_year_id",
            name: "UserProfileYear",
            props: true,
          },
          {
            path: "student_year/:student_year_id/tab/:tab",
            name: "UserProfileYearTab",
            props: true,
          },
        ],
      },
      {
        path: "profile/:id?/career/:career_id",
        name: "Career",
        component: () => import("../views/Career.vue"),
      },
      {
        path: "course/:id",
        name: "Course",
        component: () => import("../views/Course.vue"),
      },
      {
        path: "event/:id",
        name: "Event",
        component: () => import("../views/Event.vue"),
      },
      {
        path: "users/:status?",
        name: "Users",
        component: () => import("../views/Users.vue"),
        props: true,
      },
      {
        path: "students",
        name: "Students",
        component: () => import("../views/Students.vue"),
      },
      {
        path: "education",
        name: "Education",
        component: () => import("../views/Education.vue"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("../views/Login.vue"),
      },
      {
        path: "scanner",
        name: "Scanner",
        component: () => import("../views/Scanner.vue"),
      },
      // {
      //   path: "report",
      //   name: "Report",
      //   component: () => import("../views/Report.vue"),
      // },
      {
        path: "export/:section?",
        name: "Export",
        component: () => import("../views/Export.vue"),
        props: true,
      },
      {
        path: "chat",
        name: "Chats",
        component: () => import("../views/Chats.vue"),
        children: [
          {
            path: ":chat_id(\\d+)",
            name: "Chat",
            component: () => import("../components/chat/Chat.vue"),
            props: true,
          },
          {
            name: "NewChat",
            path: "new/:user_id(\\d+)",
            component: () => import("../components/chat/NewChat.vue"),
            props: true,
          },
        ],
      },
      {
        path: "surveys",
        name: "Surveys",
        component: () => import("../components/survey/Surveys.vue"),
      },
      {
        path: "survey/:id",
        name: "Survey",
        component: () => import("../components/survey/Survey.vue"),
        props: true,
      },
      {
        path: "survey-preview/:id",
        name: "SurveyPreview",
        component: () => import("../components/survey/Survey.vue"),
        props: (route) => ({ id: route.params.id, preview: true }),
      },
      {
        path: "survey-builder/:id",
        name: "SurveyBuilder",
        component: () => import("../components/survey/SurveyBuilder.vue"),
        props: true,
      },
      {
        path: "preferences",
        name: "Preferences",
        component: () => import("../views/Preferences.vue"),
        props: true,
      },
      {
        path: "announcements",
        name: "Announcements",
        component: () => import("../views/Announcements.vue"),
      },
      {
        path: "candidatures",
        name: "Candidatures",
        component: () => import("../views/Candidatures.vue"),
      },
      {
        // catch all 404 - define at the very end
        path: "*",
        component: () => import("../views/NotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const element = document.getElementById(to.hash.slice(1));
      if (element) return { el: element, behavior: "smooth" };
    }

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let language = to.params.lang;

  if (!language) {
    language = from.params.lang ?? localStorage.getItem("locale") ?? "it";
    next({ path: `/${language}${to.path}` });
    return;
  }

  if (!["it", "en"].includes(language)) {
    language = "it";
    next({ path: `/${language}${to.path.substr(3)}` });
    return;
  }

  if (i18n.locale != language) i18n.locale = language;

  if (!UNGUARDED_PAGES.includes(to.name) && !isLoggedIn()) next({ name: "Login", params: { lang: language } });
  else if (UNGUARDED_PAGES.includes(to.name) && isLoggedIn()) next({ name: "Home", params: { lang: language } });
  else next();
});

export default router;
