<template>
  <div class="py-2">
    <div class="w-100 d-flex justify-space-between align-center">
      <question-label :question="question"></question-label>
      <edit-remove-buttons
        class="flex-0"
        @edit="editQuestion(question)"
        @remove="removeQuestion(question)"
        v-if="question.survey.getPropertyValue('isBuilderState')"
      ></edit-remove-buttons>
    </div>
    <v-checkbox
      v-for="(choice, i) in question.choices"
      class="my-2"
      :key="i"
      :label="choice.text"
      :value="choice.value"
      @change="updateQuestion"
      hide-details
      :readonly="question.isReadOnly"
      :error="question.errors.length > 0"
    ></v-checkbox>
    <question-error :question="question"></question-error>
  </div>
</template>

<script>
import { RendererFactory } from "survey-core";
import question from "../../mixins/question";

RendererFactory.Instance.registerRenderer("checkbox", "sv-checkbox", "sv-checkbox");

export default {
  mixins: [question],
};
</script>
