<template>
  <v-menu
    :disabled="readonly"
    ref="menu"
    :close-on-content-click="false"
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date ? moment(date).format('L') : null"
        append-icon="mdi-calendar"
        readonly
        v-bind="{ ...attrs, ...bind }"
        v-on="on"
        :clearable="clearable"
        :append-outer-icon="appendOuterIcon"
        :error-messages="errorMessages"
        @click:append-outer="$emit('click:append-outer')"
      >
        <template v-slot:label>
          <slot name="label">{{ label }}</slot>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer"></slot>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :readonly="readonly"
      first-day-of-week="1"
      :locale="$i18n.locale"
      ref="picker"
      v-model="date"
      @change="dateChanged"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: ["value", "label", "clearable", "bind", "readonly", "append-outer-icon", "errorMessages"],

  name: "DateTextField",

  data() {
    return {
      menu: false,
      date: this.value,
      moment,
    };
  },

  watch: {
    value() {
      this.date = this.value;
    },
  },

  methods: {
    dateChanged() {
      this.menu = false;
      this.$emit("input", this.date);
    },
  },
};
</script>

<style></style>
