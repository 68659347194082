<template>
  <div>
    <div class="w-100 d-flex justify-space-between align-center">
      <question-label :question="question"></question-label>
      <edit-remove-buttons
        class="flex-0"
        @edit="editQuestion(question)"
        @remove="removeQuestion(question)"
        v-if="question.survey.getPropertyValue('isBuilderState')"
      ></edit-remove-buttons>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th></th>
          <th class="text-center" v-for="(c, i) in question.columns" :key="i">{{ c.text }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ 'error--text': question.errors.length && !value[r.value] }"
          class="text-no-wrap"
          v-for="(r, i) in question.rows"
          :key="i"
        >
          <td>
            {{ r.text }}
          </td>
          <td v-for="(c, j) in question.columns" :key="j">
            <v-radio-group
              :error="question.errors.length > 0 && !value[r.value]"
              :value="value[r.value]"
              :readonly="question.isReadOnly"
              @change="rowChanged({ [r.value]: $event })"
            >
              <v-radio class="d-flex justify-center" :value="c.value"> </v-radio>
            </v-radio-group>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <question-error :question="question"></question-error>
  </div>
</template>

<script>
import { RendererFactory } from "survey-core";
import question from "../../mixins/question";

RendererFactory.Instance.registerRenderer("matrix", "sv-matrix", "sv-matrix");

export default {
  mixins: [question],
  data() {
    return {
      value: this.question.value ?? {},
    };
  },
  methods: {
    rowChanged(row) {
      this.value = { ...this.value, ...row };

      if (this.question.rows.every(({ value }) => Object.keys(this.value).includes(value))) {
        this.updateQuestion(this.value);
      }
    },
  },
};
</script>
