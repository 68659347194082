<template>
  <div class="d-flex">
    <v-btn icon @click="$emit('edit')"><v-icon>mdi-pencil</v-icon></v-btn>
    <v-btn icon @click="$emit('remove')"><v-icon>mdi-close</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  name: "EditRemoveButtons",
};
</script>
