<template>
  <v-app>
    <v-app-bar app v-if="me">
      <router-link :to="pages.length ? pages[0].path : '/login'">
        <v-img
          alt="MAIA icon"
          class="d-block d-sm-none"
          contain
          src="@/assets/maia-logo-mobile.png"
          transition="scale-transition"
          max-width="48"
        />
        <v-img alt="MAIA logo" class="d-none d-sm-block shrink" contain src="@/assets/maia-logo.svg" max-width="150" />
      </router-link>

      <v-spacer />

      <!-- <v-switch
        v-model="$vuetify.theme.dark"
        inset
        label="Dark mode"
        hide-details="true"
      ></v-switch> -->

      <v-btn fab elevation="0" small v-if="updateExists" color="warning" class="mr-2" @click="refreshApp">
        <v-icon>mdi-transfer-up</v-icon>
      </v-btn>

      <v-btn
        v-if="me"
        class="mr-2"
        color="primary"
        dark
        fab
        small
        :to="{ name: 'Chats', params: { lang: $i18n.locale } }"
        elevation="0"
      >
        <v-icon>mdi-message</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="border" v-bind="attrs" v-on="on" size="48px">
            <v-img v-if="me.avatar" aspect-ratio="1" :src="me.avatar.link" />
            <v-img v-else aspect-ratio="1" src="@/assets/blank-avatar.png" />
          </v-avatar>
        </template>

        <v-list dense class="py-0">
          <v-list-item link :to="{ name: 'UserProfile' }">
            <v-list-item-title> {{ $t("navigation.profile") }} </v-list-item-title>
          </v-list-item>
          <v-list-item link :to="{ name: 'Preferences' }">
            <v-list-item-title> {{ $t("navigation.preferences") }} </v-list-item-title>
          </v-list-item>
          <v-list-item
            target="_blank"
            link
            :href="'https://form.asana.com/?k=OkNBK7cXrckFcppjl-bniw&d=195796052668078'"
          >
            <v-list-item-title> Segnalazioni </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            target="_blank"
            link
            v-for="customLink in me.client.customLinks"
            :key="customLink.id"
            :href="customLink.link"
          >
            <v-list-item-title> {{ customLink.name }} </v-list-item-title>
          </v-list-item>
          <v-divider v-if="me.client.customLinks.length" />
          <v-list-item link @click="logout">
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="background">
      <router-view />
    </v-main>

    <v-bottom-navigation
      class="justify-start justify-sm-center"
      style="overflow-x: scroll; overflow-y: hidden"
      v-if="isLoggedIn()"
      app
    >
      <v-btn v-for="page in pages" :key="page.path" :to="page.path">
        <span>{{ $t(`navigation.${page.name}`) }}</span>

        <v-icon>{{ page.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-overlay opacity="1.0" :value="$apollo.queries.me.loading">
      <div class="d-flex justify-center align-center flex-column">
        <v-img
          alt="MAIA logo"
          class="d-none d-sm-block shrink mb-4"
          contain
          src="@/assets/maia-logo.svg"
          max-width="400"
        />
        <v-progress-circular indeterminate color="primary" size="72"></v-progress-circular>
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
import { onLogout, isLoggedIn } from "@/vue-apollo";

import update from "./mixins/update";
// import webpush from "./webpush.js";

import gql from "graphql-tag";
import { AVAILABLE_PAGES } from "./maia/utils";

export default {
  name: "App",

  data: () => ({
    value: "home",
  }),

  apollo: {
    me: {
      query: gql`
        query Me {
          me {
            id
            type
            avatar: file(name: "avatar") {
              id
              link
            }
            client {
              id
              name
              hasCandidatures

              customLinks {
                id
                name
                link
              }
            }
          }
        }
      `,
      result() {
        this.checkLoggedIn();
      },
    },
  },

  mixins: [update],

  computed: {
    pages() {
      return this.me
        ? AVAILABLE_PAGES[this.me.type].filter((page) => !page.isEnabled || page.isEnabled(this.me.client))
        : [];
    },
  },

  watch: {
    pages() {
      if (
        this.pages.length > 0 &&
        this.$route.name === "Home" &&
        this.$route.path !== `/${this.$i18n.locale}${this.pages[0].path}`
      ) {
        this.$router.push({ path: `/${this.$i18n.locale}${this.pages[0].path}` });
      }
    },
  },

  methods: {
    async checkLoggedIn() {
      if (this.me === null && this.isLoggedIn()) {
        onLogout(this.$apollo.provider.defaultClient);
        this.$router.push({ name: "Login" });
      }
    },

    logout() {
      onLogout(this.$apollo.provider.defaultClient);
      this.$router.push({ name: "Login" });
    },

    isLoggedIn,
  },
};
</script>

<style>
@import "./assets/border.css";

/*SCROLL BARS*/

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

/* Track */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
  background: rgba(62, 155, 202, 0.8);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(62, 155, 202, 0.4);
}

.v-item-group.v-bottom-navigation .v-btn {
  background: transparent;
  height: inherit;
  min-width: 80px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
