import { Question } from "survey-core";
import QuestionLabel from "../components/generic/QuestionLabel.vue";
import QuestionError from "../components/generic/QuestionError.vue";
import EditRemoveButtons from "../components/generic/EditRemoveButtons.vue";

export default {
  props: {
    question: Question,
  },

  components: {
    EditRemoveButtons,
    QuestionLabel,
    QuestionError,
  },

  inject: ["editQuestion", "removeQuestion"],

  methods: {
    updateQuestion(value) {
      if (this.question.getType() == "matrix") this.question.value = { ...this.question.value, ...value };
      else this.question.value = value;
    },
  },

  computed: {
    errorMessages() {
      return this.question.errors.map(() => {
        return this.$t("survey.errors.response_required");
        //return error.locTextValue.renderedText;
      });
    },
  },

  inheritAttrs: false,
};
