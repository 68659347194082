import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import { createProvider } from "./vue-apollo";
import i18n from "./i18n";
import FlagIcon from "vue-flag-icon";
import Vuelidate from "vuelidate";
import "./registerServiceWorker";
import SurveyTextField from "./components/survey/components/inputs/SurveyTextField.vue";
import SurveyDatePicker from "./components/survey/components/inputs/SurveyDatePicker.vue";
import SurveyRadioGroup from "./components/survey/components/inputs/SurveyRadioGroup.vue";
import SurveyCheckbox from "./components/survey/components/inputs/SurveyCheckbox.vue";
import SurveyRating from "./components/survey/components/inputs/SurveyRating.vue";
import SurveyTextArea from "./components/survey/components/inputs/SurveyTextArea.vue";
import SurveyMatrix from "./components/survey/components/inputs/SurveyMatrix.vue";
import { Serializer } from "survey-core";

require("babel-polyfill");

require("moment/locale/it");

Vue.use(FlagIcon);

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Vue.component("sv-text-field", SurveyTextField);
Vue.component("sv-date-picker", SurveyDatePicker);
Vue.component("sv-radiogroup", SurveyRadioGroup);
Vue.component("sv-checkbox", SurveyCheckbox);
Vue.component("sv-rating", SurveyRating);
Vue.component("sv-text-area", SurveyTextArea);
Vue.component("sv-matrix", SurveyMatrix);

Serializer.addProperty("survey", {
  name: "isBuilderState",
  type: "boolean",
  category: "general",
  default: false,
});

new Vue({
  router,
  vuetify,
  apolloProvider: createProvider(),
  i18n,
  render: (h) => h(App),
}).$mount("#app");
