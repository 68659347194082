<template>
  <div>
    <div class="w-100 d-flex justify-space-between align-center">
      <question-label :question="question"></question-label>
      <edit-remove-buttons
        class="flex-0"
        @edit="editQuestion(question)"
        @remove="removeQuestion(question)"
        v-if="question.survey.getPropertyValue('isBuilderState')"
      ></edit-remove-buttons>
    </div>
    <v-radio-group
      :value="question.value"
      @change="updateQuestion"
      :readonly="question.isReadOnly"
      :error-messages="errorMessages"
    >
      <v-radio v-for="(choice, i) in question.choices" :key="i" :label="choice.text" :value="choice.value"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { RendererFactory } from "survey-core";
import question from "../../mixins/question";

RendererFactory.Instance.registerRenderer("radiogroup", "sv-radiogroup", "sv-radiogroup");

export default {
  mixins: [question],
};
</script>
