import Vue from "vue";
import VueApollo from "vue-apollo";
import PusherLink from "@/pusher-link";
import Pusher from "pusher-js/with-encryption";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { createApolloClient, restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "../fragmentTypes.json";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

const API_LOCATION = process.env.VUE_APP_API_URL + (process.env.VUE_APP_API_PORT ? `:${process.env.VUE_APP_API_PORT}` : "");

// const API_LOCATION = "https://maia.bytenest.it:44300";

// Http endpoint
const httpEndpoint = `${API_LOCATION}/graphql`;

const pusherLink = new PusherLink({
  pusher: new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
    wsHost: process.env.VUE_APP_WEBSOCKET_URL,
    authEndpoint: `${httpEndpoint}/subscriptions/auth`,
    ...(process.env.VUE_APP_TLS_STATUS == "enabled"
      ? {
          wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
          forceTLS: true,
        }
      : {
          wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
          forceTLS: false,
          enabledTransports: ["ws"],
        }),
  }),
});

const httpLink = createUploadLink({
  uri: httpEndpoint,
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  // Override default apollo link
  link: ApolloLink.from([pusherLink, httpLink]),

  defaultHttpLink: false,

  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        //fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log("%cError", "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;", error.message);
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}

export function isLoggedIn() {
  return localStorage.getItem(AUTH_TOKEN) !== null;
}
