import moment from "moment";

const DING_AUDIO = require("../assets/ding.mp3");

export const extractModelInfo = (item, model) => {
  return Object.keys(item)
    .filter((key) => typeof item[key] != "object" && !["id", "__typename"].includes(key))
    .map((field) => ({ field, translation: `${model}.${field}` }));
};

/**
 * Play a ding sound
 */
export const playSound = () => {
  let note = new Audio(DING_AUDIO);
  return note.play();
};

export const getTimeSlots = (start, end, increment = 15) => {
  var startTime = moment(start, "HH:mm");
  var endTime = moment(end, "HH:mm");

  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  var timeStops = [];

  while (startTime <= endTime) {
    timeStops.push(new moment(startTime).format("HH:mm"));
    startTime.add(increment, "minutes");
  }
  return timeStops;
};

export const AVAILABLE_PAGES = {
  super_admin: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    {
      path: "/meals",
      icon: "mdi-silverware-fork-knife",
      name: "meals",
    },
    { path: "/users", icon: "mdi-account-multiple", name: "users" },
    { path: "/administration", icon: "mdi-cogs", name: "admin" },
    // { path: "/report", icon: "mdi-chart-box", name: "report" },
    { path: "/export", icon: "mdi-download", name: "export" },
    { path: "/dashboard", icon: "mdi-view-dashboard", name: "dashboard" },
    { path: "/surveys", icon: "mdi-note-edit", name: "surveys" },
    { path: "/announcements", icon: "mdi-bullhorn", name: "announcements" },
    {
      path: "/candidatures",
      icon: "mdi-account-box-plus-outline",
      name: "candidatures",
      isEnabled: (client) => client.hasCandidatures,
    },
  ],
  client_admin: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    {
      path: "/meals",
      icon: "mdi-silverware-fork-knife",
      name: "meals",
    },
    { path: "/users", icon: "mdi-account-multiple", name: "users" },
    { path: "/administration", icon: "mdi-cogs", name: "admin" },
    { path: "/export", icon: "mdi-download", name: "export" },
    { path: "/dashboard", icon: "mdi-view-dashboard", name: "dashboard" },
    // { path: "/import", icon: "mdi-upload", name: "import" },
    // { path: "/report", icon: "mdi-chart-box", name: "report" },
    { path: "/scanner", icon: "mdi-camera", name: "scanner" },
    { path: "/surveys", icon: "mdi-note-edit", name: "surveys" },
    { path: "/announcements", icon: "mdi-bullhorn", name: "announcements" },
    {
      path: "/candidatures",
      icon: "mdi-account-box-plus-outline",
      name: "candidatures",
      isEnabled: (client) => client.hasCandidatures,
    },
  ],
  area_admin: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    {
      path: "/meals",
      icon: "mdi-silverware-fork-knife",
      name: "meals",
    },
    { path: "/users", icon: "mdi-account-multiple", name: "users" },
    { path: "/dashboard", icon: "mdi-view-dashboard", name: "dashboard" },
    // { path: "/administration", icon: "mdi-cogs", name: "admin" },
    { path: "/scanner", icon: "mdi-camera", name: "scanner" },
    { path: "/export", icon: "mdi-download", name: "export" },
    { path: "/surveys", icon: "mdi-note-edit", name: "surveys" },
    { path: "/announcements", icon: "mdi-bullhorn", name: "announcements" },
  ],
  residence_admin: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    {
      path: "/meals",
      icon: "mdi-silverware-fork-knife",
      name: "meals",
    },
    { path: "/users", icon: "mdi-account-multiple", name: "users" },
    { path: "/dashboard", icon: "mdi-view-dashboard", name: "dashboard" },
    // { path: "/administration", icon: "mdi-cogs", name: "admin" },
    { path: "/scanner", icon: "mdi-camera", name: "scanner" },
    { path: "/export", icon: "mdi-download", name: "export" },
    { path: "/surveys", icon: "mdi-note-edit", name: "surveys" },
    { path: "/announcements", icon: "mdi-bullhorn", name: "announcements" },
  ],
  course_admin: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    { path: "/profile", icon: "mdi-face-man", name: "profile" },
  ],
  meals_admin: [
    {
      path: "/meals",
      icon: "mdi-silverware-fork-knife",
      name: "meals",
    },
  ],
  teacher: [
    { path: "/students", icon: "mdi-account-multiple", name: "students" },
    { path: "/profile", icon: "mdi-face-man", name: "profile" },
  ],
  student: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    { path: "/profile", icon: "mdi-face-man", name: "profile" },
    { path: "/scanner", icon: "mdi-camera", name: "scanner" },
  ],
  candidate: [{ path: "/profile", icon: "mdi-face-man", name: "profile" }],
  guest: [
    { path: "/", icon: "mdi-home", name: "home" },
    { path: "/education", icon: "mdi-school", name: "education" },
    { path: "/profile", icon: "mdi-face-man", name: "profile" },
    { path: "/scanner", icon: "mdi-camera", name: "scanner" },
  ],
  receptionist: [
    { path: "/users", icon: "mdi-account-multiple", name: "users" },
    { path: "/profile", icon: "mdi-face-man", name: "profile" },
    { path: "/scanner", icon: "mdi-camera", name: "scanner" },
  ],
};
