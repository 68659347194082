<template>
  <legend v-if="question.errors.length" class="flex-1 v-label error--text v-messages">
    {{ $t("survey.errors.response_required") }}
  </legend>
</template>

<script>
export default {
  name: "QuestionError",
  props: {
    question: Object,
  },
};
</script>
