<template>
  <v-textarea
    :readonly="question.isReadOnly"
    :type="question.inputType"
    :value="question.value"
    :error-messages="errorMessages"
    @input="updateQuestion"
  >
    <template v-slot:label> <question-label :question="question"></question-label> </template>
    <template v-slot:append-outer v-if="question.survey.getPropertyValue('isBuilderState')">
      <edit-remove-buttons @edit="editQuestion(question)" @remove="removeQuestion(question)"></edit-remove-buttons>
    </template>
  </v-textarea>
</template>

<script>
import { RendererFactory } from "survey-core";
import question from "../../mixins/question";

RendererFactory.Instance.registerRenderer("text", "text-area", "sv-text-area");

export default {
  mixins: [question],
};
</script>
