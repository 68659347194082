<template>
  <legend :class="{ 'error--text': question.errors.length > 0 }" class="flex-1 v-label">
    {{ label }}<v-icon x-small class="ml-1 red--text" v-if="question.isRequired">mdi-asterisk</v-icon>
  </legend>
</template>

<script>
export default {
  name: "QuestionLabel",
  data() {
    return {
      refresh: 0,
    };
  },
  props: {
    question: Object,
  },
  mounted() {
    this.question.onPropertyChanged.add(() => {
      this.refresh++;
    });
  },
  computed: {
    label() {
      this.refresh;
      return `${this.question.no} ${this.question.title}`;
    },
  },
};
</script>
