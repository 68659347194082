<template>
  <div class="py-2">
    <div class="w-100 d-flex justify-space-between align-center">
      <question-label :question="question"></question-label>
      <edit-remove-buttons
        class="flex-0"
        @edit="editQuestion(question)"
        @remove="removeQuestion(question)"
        v-if="question.survey.getPropertyValue('isBuilderState')"
      ></edit-remove-buttons>
    </div>
    <v-rating
      :length="question.rateCount"
      :readonly="question.isReadOnly"
      :value="parseInt(question.value)"
      :error-messages="errorMessages"
      @input="updateQuestion"
    ></v-rating>
  </div>
</template>

<script>
import { RendererFactory } from "survey-core";
import question from "../../mixins/question";

RendererFactory.Instance.registerRenderer("rating", "sv-rating", "sv-rating");

export default {
  mixins: [question],
};
</script>
